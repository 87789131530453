import React from 'react'
import './PreRenderedAnimation.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const PreRenderedAnimation = ({data}) => {
    return (
      <div className="pre-rendered-container d-lg-flex justify-content-between align-items-lg-center" id="common-pl">
        <div className="title-container first-flex-column">
          <p className="sub-title">MORE ABOUT</p>
          <Fade timeout={500}>
            <h2>{data.title}</h2>
          </Fade>
          <Fade timeout={1000}>
              <p className="title-dec">
                <Marked parserOptions={{ commonmark: true}} source={data.description} />
              </p>
          </Fade>
        </div>
        <div className="img-container second-flex-column">
          <Fade timeout={1000}>
            <img src={data.image} alt={data.alt} className="pre-rendered-img" />
          </Fade>
        </div>
        <div className="red-angle-container d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614326711/angle_lzbgnu.png"
            alt="left angle"
            className="menu-angle"
          />
        </div>
      </div>
    )
}
export default PreRenderedAnimation